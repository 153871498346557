import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css<{ loading: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  footerContainer: css<{ loading: boolean }>`
    margin-top: ${({ loading }) => (loading ? 'auto' : 0)};
  `,
  supportLinksWrapper: css<{ isMobile?: boolean }>`
    margin-horizontal: 16px;
    margin-vertical: ${({ isMobile }) => (isMobile ? '24px' : '32px')};
    align-items: center;
  `,
  schoolSearchContainer: css`
    width: 100%;
    max-width: 960px;
    z-index: 4;
  `,
  schoolSearchWrapper: css<{ isMobile: boolean; displaySearch: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;
    padding-horizontal: 32px;
    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
    min-width: ${({ isMobile }) => (isMobile ? '100%' : '354px')};
    height: ${({ displaySearch }) => (displaySearch ? '80px' : 'auto')};
    max-height: 240px;
  `,
  schoolSearchTitle: css`
    height: 24px;
    color: #161616;
    font-size: 16px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
    margin-bottom: 4px;
  `,
  overlay: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: dimgrey;
    opacity: 0.2;
    z-index: 3;
  `,
  showMoreContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  `,
  showMoreSection: css<{ center: boolean }>`
    width: 311px;
    display: flex;
    flex-direction: row;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    gap: 16px;
    margin-top: 32px;
  `,
  viewAllSchoolsWrapper: css`
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
  `,
  viewAllSchoolsContainer: css`
    width: 100%;
    align-items: center;
    margin-top: 16px;
    padding-horizontal: 32px;
  `,
  filtersContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px 10px 16px;
    margin-top: 8px;
    width: 100%;
    max-width: 960px;
    margin-bottom: 8px;
  `,
  filtersText: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Rubik-Regular';
    align-self: center;
    padding-right: 8px;
  `,
  border: css`
    height: 100%;
    width: 1px;
    background-color: #f5f5f5;
  `
};
