import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './FundraiserFooter.styles';
import { EventFooterItem } from '../EventFooterItem';
import { View } from 'react-native';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const TagWrapper = styled(View)`
  ${styles.tagWrapper};
`;
export interface FundraiserFooterProps {
  isMobile: boolean;
  isFeatured: boolean;
  dateRange: string | null;
  venueTitle: string | null;
}

export const FundraiserFooter = ({ isMobile, isFeatured, dateRange, venueTitle }: FundraiserFooterProps) => (
  <Wrapper isMobile={isMobile}>
    <TagWrapper isMobile={isMobile}>
      {venueTitle && <EventFooterItem iconName={isMobile ? 'locationFilled' : 'location'} title={venueTitle} />}
      {dateRange && <EventFooterItem iconName='calendarHeatMap' title={dateRange} />}
      {isFeatured && <EventFooterItem iconName='star' title='Featured' />}
    </TagWrapper>
  </Wrapper>
);
