import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  contentWrapper: css<{ primaryColor: string; isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid rgb(151, 151, 151);
    margin-top: 8px;
    background-color: white;
    border-top-width: ${({ primaryColor }) => (primaryColor.toLowerCase() === '#ffffff' ? `1px` : `5px`)};
    border-top-color: ${({ primaryColor }) =>
      primaryColor.toLowerCase() === '#ffffff' ? `rgb(151, 151, 151)` : `${primaryColor}`};
  `,
  wrapper: css<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    padding: 16px;
  `,
  dateSection: css<{ isMobile: boolean }>`
    white-space: nowrap;
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    align-items: center;
  `,
  rightArrowSection: css`
    align-self: center;
    display: flex;
    align-items: center;
  `,
  featuredSection: css`
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  mobileFooter: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  mobileLeftSection: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  `,
  mobileTag: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `,
  iconWrapper: css`
    margin-right: 4px;
    height: 20px;
  `,
  moreWrapper: css`
    display: flex;
    flex-direction: row;
    color: #282828;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    align-items: center;
    border: 0;
    background-color: transparent;
  `,
  mobileRightSection: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  mobileInfo: css`
    display: flex;
    flex-direction: row;
    margin-top: 0;
  `,
  mobileHeader: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  `,
  leftItems: css`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: red;
  `,
  seasonTag: css<{ isMobile: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
    border-radius: 12px;
    background-color: #f0f0f0;
    margin-left: 8px;
  `,
  seasonText: css`
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
    color: #323232;
  `
};
