import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { EventSeason } from '@gf/cross-platform-lib/interfaces';
import { GENDERS, NONLEVEL_GENDER_EVENT_TYPE, activityIcons } from '@gf/cross-platform-lib/constants';
import { Dimensions } from 'react-native';

export type FilterURLType = {
  [key: string]: any;
};

type QueryStringType = {
  activities: string;
  homeAway: string;
  levels: string;
  genders: string;
  playoffs: string;
  schoolId: string;
};

export type FilterType = {
  activities: string[];
  homeAway: string[];
  levels: string[];
  genders: string[];
  playoffs: boolean;
  schoolFilter: string;
};

export type SelectedFilter = FilterType & {
  dataLoaded: boolean;
};

export type ActivityFilter = {
  name: string;
  priority: number;
};

export type FilterCategory = 'activities' | 'homeAway' | 'levels' | 'genders';

const isNonLevelGenderEventType = (event: EventSeason) =>
  event.eventTypeName ? NONLEVEL_GENDER_EVENT_TYPE.includes(event.eventTypeName) : false;

export const listGendersAvailable = (events: EventSeason[]) => {
  let uniqueGenders: string[] = [];
  if (!isEmpty(events)) {
    uniqueGenders = flatten(
      events.map(event => {
        const noGenders = isEmpty(event.genders);

        if (isNonLevelGenderEventType(event)) {
          return [GENDERS.COED];
        }
        return (event.levels || [])
          .map(({ genders }) => {
            if (Array.isArray(genders)) {
              return genders.length > 0 ? genders : [GENDERS.COED];
            } else {
              return [];
            }
          })
          .concat(noGenders ? [GENDERS.COED] : event.genders)
          .flat();
      })
    );
  }
  if (uniqueGenders.length === 0) {
    uniqueGenders.push(GENDERS.COED);
  }
  return uniq(uniqueGenders);
};

export const mapToQueryStringFilter = (selectedFilter: SelectedFilter) => {
  const { activities = [], homeAway, levels, genders, playoffs, schoolFilter } = selectedFilter || {};
  return {
    ...selectedFilter,
    activities: !isEmpty(activities) ? activities.join() : '',
    levels: !isEmpty(levels) ? levels.join(',') : '',
    genders: !isEmpty(genders) ? genders.join(',') : '',
    homeAway: !isEmpty(homeAway) ? homeAway.join(',') : '',
    playoffs: playoffs ? String(playoffs) : '',
    schoolId: schoolFilter
  };
};

export const getQueryStringFilter = ({
  activities,
  homeAway,
  levels,
  genders,
  playoffs,
  schoolId
}: QueryStringType) => {
  const activitiesQuery = !isEmpty(activities) ? `&activity=${activities}` : '';
  const levelsQuery = !isEmpty(levels) ? `&level=${levels}` : '';
  const gendersQuery = !isEmpty(genders) ? `&gender=${genders}` : '';
  const schoolQuery = !isEmpty(schoolId) ? `&school=${schoolId}` : '';
  const playOffsQuery = playoffs ? `&playoffs=${playoffs}` : '';
  const homeAwayQuery = !isEmpty(homeAway) ? `&location=${homeAway}` : '';
  return [activitiesQuery, homeAwayQuery, levelsQuery, gendersQuery, schoolQuery, playOffsQuery]
    .join('')
    .replace('&', '?');
};

export const getSelectableItemData = (type: string, filter: ActivityFilter | string, selectedFilter: any) => {
  let filterIcon = '';
  let filterName = '';
  let isSelected;

  switch (type) {
    case 'activities': {
      filter = filter as ActivityFilter;
      filterName = filter.name;
      isSelected = selectedFilter.activities.includes(filter.name);
      const activityIconName = filter.name.toLowerCase().replace(/ /g, '');
      filterIcon = activityIcons.hasOwnProperty(activityIconName)
        ? activityIcons[activityIconName as keyof typeof activityIcons]
        : activityIcons.unlabeled;
      break;
    }
    case 'levels': {
      filter = filter as string;
      filterName = filter;
      isSelected = selectedFilter.levels.includes(filter);
      filterIcon = `level-icons/${filter.toLowerCase().replace(' ', '-')}.svg`;
      break;
    }
    case 'genders': {
      filter = filter as string;
      filterName = filter;
      isSelected = selectedFilter.genders.includes(filter);
      filterIcon = `gender-icons/${filter.toLowerCase()}.png`;
      break;
    }
    case 'other': {
      filter = filter as ActivityFilter;
      filterName = filter.name;
      isSelected = selectedFilter.activities.includes(filter.name);
      const otherIconName = filter.name.toLowerCase().replace(/ /g, '');
      filterIcon = activityIcons.hasOwnProperty(otherIconName)
        ? activityIcons[otherIconName as keyof typeof activityIcons]
        : activityIcons.unlabeled;
      break;
    }
    case 'homeAway': {
      filter = filter as string;
      filterName = filter;
      isSelected = selectedFilter.homeAway.includes(filter);
      filterIcon = `${filter.toLowerCase()}.png`;
      break;
    }
    default:
      break;
  }

  return {
    filterIcon,
    filterName,
    isSelected
  };
};

export const hasFilterChanged = (selectedFilter: SelectedFilter, appliedFilter: SelectedFilter) => {
  const selectedActivities = selectedFilter.activities;
  const selectedLevels = selectedFilter.levels;
  const selectedGenders = selectedFilter.genders;
  const selectedHomeAway = selectedFilter.homeAway;
  const selectedPlayOffs = selectedFilter.playoffs;
  const appliedActivities = appliedFilter.activities;
  const appliedLevels = appliedFilter.levels;
  const appliedGenders = appliedFilter.genders;
  const appliedHomeAway = appliedFilter.homeAway;
  const appliedPlayOffs = appliedFilter.playoffs;

  if (
    selectedActivities.some((activity: string) => !appliedActivities.includes(activity)) ||
    appliedActivities.some((activity: string) => !selectedActivities.includes(activity))
  )
    return true;

  if (
    selectedLevels.some((activity: string) => !appliedLevels.includes(activity)) ||
    appliedLevels.some((activity: string) => !selectedLevels.includes(activity))
  )
    return true;

  if (
    selectedGenders.some((activity: string) => !appliedGenders.includes(activity)) ||
    appliedGenders.some((activity: string) => !selectedGenders.includes(activity))
  )
    return true;

  if (
    selectedHomeAway.some((activity: string) => !appliedHomeAway.includes(activity)) ||
    appliedHomeAway.some((activity: string) => !selectedHomeAway.includes(activity))
  )
    return true;

  if (selectedPlayOffs !== appliedPlayOffs) return true;

  return false;
};

const screenHeight = Dimensions.get('window').height;
export const drawerMinHeight = 256;
export const drawerMaxHeight = screenHeight * 0.7;
export const calculateDrawerHeight = (itemCount: number) => {
  // TODO: find a better solution that doesn't use a hardcoded assumption for the child elements in the list https://huddleinc.atlassian.net/browse/FAN-6796
  const listHeight = 42 * itemCount;
  const componentHeight = listHeight + 170;
  let calculatedHeight;
  if (componentHeight < drawerMinHeight) {
    calculatedHeight = drawerMinHeight;
  } else if (componentHeight > drawerMaxHeight) {
    calculatedHeight = drawerMaxHeight;
  } else {
    calculatedHeight = componentHeight;
  }
  return calculatedHeight;
};

export const levelMapper = (level: string) => {
  switch (level) {
    case 'JV':
      return 'Junior Varsity';
    case 'MS':
      return 'Middle School';
    default:
      return level;
  }
};
