import React, { ReactNode } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { ToggleFavorite } from '../ToggleFavorite';
import { styles } from './SchoolHeader.styles';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { invertColor, schoolPage, testProperties } from '@gf/cross-platform-lib/utils';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { ArrowRight } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { LightenDarkenColor } from '@gf/cross-platform-lib/utils/lightenDarkenColor';
import { ATHLETIC_TEAMS_LIST, SCHOOL_DISTRICT_LIST, useDrawerContext } from '@gf/cross-platform-lib/providers';
import { TeamSitePromotion } from '@gf/cross-platform-lib/components/TeamSitePromotion/TeamSitePromotion';
import { useLaunchDarklyContext, ITeamSitePromotion } from '@gf/cross-platform-lib/providers/LaunchDarkly';
import { SchoolLogo } from '@gf/cross-platform-lib/components/SchoolLogo';
import { SchoolHeaderLegacy } from './Legacy/SchoolHeaderLegacy';

const Wrapper = styled(View)`
  ${styles.wrapper}
`;
const ContentWrapper = styled(View)`
  ${styles.contentWrapper}
`;
const TextContainer = styled(View)`
  ${styles.textContainer}
`;
const Title = styled(Text)`
  ${styles.title}
`;
const TeamsLink = styled(Text)`
  ${styles.teamsLink}
`;
const SitePromoContainer = styled(View)`
  ${styles.sitePromoContainer}
`;
const CenterRow = styled(View)`
  ${styles.centerRow}
`;
const ViewDistrictBtnContainer = styled(View)`
  ${styles.viewDistrictBtnContainer}
`;
const ViewDistrictBtn = styled(TouchableOpacity)<{ color: string }>`
  ${styles.viewDistrictBtn}
`;
const ViewSchoolsText = styled(Text)`
  ${styles.viewSchoolsText}
`;

export interface SchoolHeaderProps {
  primaryColor?: string;
  schoolName: string;
  logo?: string;
  isFavorite: boolean;
  toggleFavorite: () => void;
  customPageName?: string;
  showDistrictButton: boolean;
  schoolId: string;
}

export const SchoolHeader = ({
  primaryColor = '#000000',
  schoolName = '',
  logo,
  isFavorite,
  toggleFavorite,
  customPageName,
  showDistrictButton,
  schoolId
}: SchoolHeaderProps) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const schoolIdsToSiteUrls = launchDarklyContext.features['teams-sites-promotion'].variation as {
    [key: string]: ITeamSitePromotion;
  };
  const teamsInGoFanFlag = launchDarklyContext.features['teams-in-gofan'].variation as boolean;
  const teamsInGoFanFavoritingFlag = launchDarklyContext.features['teams-in-gofan-favoriting'].variation as boolean;
  const schoolSite = schoolIdsToSiteUrls[schoolId];
  const { isMobile } = useMediaQuery();
  const schoolLogo = logo || 'gofan-icon.png';
  let title = customPageName || schoolName;
  const { openDrawer } = useDrawerContext();
  const invertedColor = invertColor(primaryColor, true);
  const headerHeight = isMobile && !!schoolSite ? 140 : 96;

  if (teamsInGoFanFlag) {
    return (
      <Gradient schoolColor={primaryColor} headerHeight={headerHeight}>
        <ContentWrapper isMobile={isMobile}>
          <CenterRow>
            <ToggleFavorite
              isFavorite={isFavorite}
              toggleFavorite={toggleFavorite}
              iconHeight='12px'
              iconWidth='12px'
              iconFill={invertedColor}
              backgroundOpacity={0.18}
            />
            <View style={{ marginLeft: 16 }}></View>
            <SchoolLogo logoSrc={schoolLogo} schoolColor={primaryColor} />
            <TextContainer>
              <Title numberOfLines={1} textColor={invertedColor} {...testProperties(schoolPage.schoolName)}>
                {title}
              </Title>
              {teamsInGoFanFavoritingFlag && (
                <TouchableOpacity onPress={() => openDrawer(ATHLETIC_TEAMS_LIST)} style={{ alignSelf: 'flex-start' }}>
                  <TeamsLink numberOfLines={1} textColor={invertedColor} {...testProperties('view-athletic-teams')}>
                    View athletic teams
                  </TeamsLink>
                </TouchableOpacity>
              )}
            </TextContainer>
          </CenterRow>
          {schoolSite !== undefined && (
            <SitePromoContainer isMobile={isMobile}>
              <TeamSitePromotion
                fullWidth={isMobile ? true : false}
                hexColor={invertedColor}
                displayName={schoolSite.displayName}
                src={schoolSite.src}
                enableBoldText={true}
                schoolId={schoolId}
                componentLocation='event list'
              />
            </SitePromoContainer>
          )}
          {showDistrictButton && !isMobile && (
            <ViewDistrictBtnContainer>
              <ViewDistrictBtn onPress={() => openDrawer(SCHOOL_DISTRICT_LIST)} color={invertedColor}>
                <ViewSchoolsText color={invertedColor}>View all schools</ViewSchoolsText>
                <ArrowRight fill={invertedColor} height={16} width={16} />
              </ViewDistrictBtn>
            </ViewDistrictBtnContainer>
          )}
        </ContentWrapper>
      </Gradient>
    );
  } else {
    return (
      <SchoolHeaderLegacy
        logo={logo}
        primaryColor={primaryColor}
        schoolName={schoolName}
        isFavorite={isFavorite}
        toggleFavorite={toggleFavorite}
        customPageName={customPageName}
        showDistrictButton={showDistrictButton}
        schoolId={schoolId}
      />
    );
  }
};

type GradientProps = {
  children: ReactNode;
  schoolColor: string;
  headerHeight: number;
};

const Gradient = ({ children, schoolColor, headerHeight }: GradientProps) => {
  const schoolColorGradientTo = LightenDarkenColor(schoolColor, -60);
  return (
    <Wrapper
      {...testProperties(schoolPage.schoolInfoSection)}
      backgroundColor={schoolColor}
      headerHeight={headerHeight}
    >
      <Svg height='100%' width='100%' style={StyleSheet.absoluteFillObject}>
        <Defs>
          <LinearGradient id={`${schoolColor}`} x1='0%' y1='100%' x2='0%' y2='0%'>
            <Stop
              offset='0%'
              stopColor={schoolColor !== '000000' ? `#${schoolColorGradientTo}` : '#323232'}
              stopOpacity={1}
            />
            <Stop offset='100%' stopColor={schoolColor !== '000000' ? `${schoolColor}` : '#323232'} stopOpacity={1} />
          </LinearGradient>
        </Defs>
        <Rect width='100%' height='100%' fill={`url(#${schoolColor})`} />
      </Svg>
      {children}
    </Wrapper>
  );
};
