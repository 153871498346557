import React, { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import {
  ActivityFilter,
  calculateDrawerHeight,
  hasFilterChanged,
  listGendersAvailable,
  SelectedFilter
} from '@gf/cross-platform-lib/utils/filterUtils';
import {
  activityIcons,
  FILTER_ACTIVITY,
  FILTER_BATCH,
  FILTER_GENDER,
  FILTER_HOMEAWAY,
  FILTER_LEVEL,
  FILTER_OTHER,
  SORTED_LEVELS
} from '@gf/cross-platform-lib/constants';
import { CategoriesType, EventSeason } from '@gf/cross-platform-lib/interfaces';
import { View, Text } from 'react-native';
import { schoolPage, sortedArray, testProperties } from '@gf/cross-platform-lib/utils';
import { SelectableItem } from '@gf/cross-platform-lib/components/SelectableItem/SelectableItem';
import Dropdown from '@gf/cross-platform-lib/components/Dropdown/Dropdown';
import { GFImage } from '@gf/cross-platform-lib/components';
import { MULTISELECT_BOTTOM_DRAWER } from '@gf/cross-platform-lib/providers/DrawerProvider/drawerConstants';
import { FilterDrawer } from './FilterDrawer';
import { DrawerContent } from '@gf/cross-platform-lib/providers';
import {
  filterOnlyOtherActivities,
  filterOutOtherActivities
} from '@gf/cross-platform-lib/modules/school/container/SchoolDetailContainer';

const Container = styled(View)`
  ${styles.container};
`;

const EmptyTag = styled(View)`
  ${styles.emptyTag};
`;

const IconCircle = styled(View)<{ primaryColor: string }>`
  ${styles.iconCircle};
`;

const IconContainer = styled(View)`
  ${styles.iconContainer};
`;

const CountCircle = styled(View)`
  ${styles.countCircle};
`;

const CountText = styled(Text)`
  ${styles.countText};
`;
const FilterItemContainer = styled(View)`
  ${styles.filterItemContainer};
`;
const FilterItemText = styled(Text)`
  ${styles.filterItemText};
`;

const PlayoffsDivider = styled(View)`
  ${styles.playoffsDivider};
`;

interface CategoryFiltersProps {
  events: EventSeason[];
  appliedFilter: SelectedFilter;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  resetFilters: (value: string) => void;
  primaryColor?: string;
  listCategories: CategoriesType;
  loadingCategories: boolean;
  hasLocationFilter: boolean;
}

const createFilterObject = (name: string, priority: number) => {
  return {
    name,
    priority
  };
};

const getAvailableActivities = (activities: { customSportName: string | null; id: string | number; name: string }[]) =>
  [...uniqBy(activities, act => act.customSportName || act.name)].map(activity => {
    const actName = activity.customSportName || activity.name;
    return createFilterObject(actName, actName === 'Fundraiser' ? 1 : 2);
  });

export const CategoryFilters = ({
  events,
  applyFilter,
  appliedFilter,
  primaryColor,
  loadingCategories,
  listCategories,
  resetFilters,
  hasLocationFilter
}: CategoryFiltersProps) => {
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({ ...appliedFilter });
  const { activities = [], levels = [], genders: allGenders = [], playOff } = listCategories || {};
  const { isMobile, isNativeApp } = useMediaQuery();
  const sortedLevels = sortedArray(levels, (a, b) => SORTED_LEVELS.indexOf(a) - SORTED_LEVELS.indexOf(b));
  const filterLoaded = !loadingCategories && !isEmpty(listCategories);
  const filterList = filterLoaded ? [...getAvailableActivities(activities)] : [];
  const genders = filterLoaded ? uniq(allGenders) : listGendersAvailable(events);
  const shouldShowPlayOff = filterLoaded ? !!playOff : events.some((event: any) => event.isPostSeason);
  const shouldShowLevels = !isEmpty(listCategories) ? sortedLevels.length > 0 : !isEmpty(events);
  const homeAwayFilters = ['Home', 'Away'];

  const shouldShowGenders = genders.length > 0;
  if (shouldShowLevels)
    sortedLevels.forEach(level => {
      level && filterList.push(createFilterObject(level, 3));
    });
  if (shouldShowGenders)
    genders.forEach(gender => {
      gender && filterList.push(createFilterObject(gender, 4));
    });

  if (shouldShowPlayOff) filterList.push(createFilterObject('Playoffs', 1));
  const shouldDisplayFilters = filterList.length > 0;

  const renderPlaceholder = (isOpen: boolean, selectedCount: number, category: string) => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {selectedCount > 0 && (
        <CountCircle>
          <CountText isNativeApp={isNativeApp}>{selectedCount}</CountText>
        </CountCircle>
      )}
      <Text style={{ marginRight: 10, marginLeft: selectedCount > 0 ? 4 : 12 }}>{`${category}`}</Text>
    </View>
  );

  const allActivityFilters = useMemo(() => {
    return getAvailableActivities(listCategories.activities);
  }, [listCategories.activities]);

  const otherActivitiesNames = ['Parking', 'Digital Season Pass', 'Fundraiser'];
  const normalActivities = allActivityFilters.filter(activity => !otherActivitiesNames.includes(activity.name));
  const otherActivities = allActivityFilters.filter(activity => otherActivitiesNames.includes(activity.name));
  const sortedFilters = [...normalActivities].sort((a, b) => a.name.localeCompare(b.name));

  const onActivityFilterPress = (selected: boolean, filter: ActivityFilter) => {
    if (selected) {
      setSelectedFilter({
        ...selectedFilter,
        activities: selectedFilter.activities.filter(activity => activity !== filter.name)
      });
    } else {
      setSelectedFilter({ ...selectedFilter, activities: [...selectedFilter.activities, filter.name] });
    }
  };

  const onPlayoffFilterPress = (selected: boolean) => {
    if (selected) {
      setSelectedFilter({
        ...selectedFilter,
        playoffs: false
      });
    } else {
      setSelectedFilter({ ...selectedFilter, playoffs: true });
    }
  };

  const renderActivityItems = () => {
    const activityItems = sortedFilters.map(filter => {
      const selected = selectedFilter.activities?.includes(filter.name);
      const iconName = filter.name.toLowerCase().replace(/ /g, '');
      const activityIcon = activityIcons.hasOwnProperty(iconName)
        ? activityIcons[iconName as keyof typeof activityIcons]
        : activityIcons.unlabeled;

      return (
        <SelectableItem
          key={filter.name}
          value={filter.name}
          isSelected={selected}
          onPress={() => onActivityFilterPress(selected, filter)}
        >
          <FilterItemContainer>
            <IconCircle primaryColor={primaryColor}>
              <IconContainer>
                <GFImage name={activityIcon} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
              </IconContainer>
            </IconCircle>
            <FilterItemText numberOfLines={1}>{filter.name}</FilterItemText>
          </FilterItemContainer>
        </SelectableItem>
      );
    });

    if (shouldShowPlayOff) {
      const selected = selectedFilter.playoffs;
      const playoffsItem = (
        <>
          <SelectableItem
            key='playoffs'
            value='true'
            isSelected={selectedFilter.playoffs}
            onPress={() => onPlayoffFilterPress(selected)}
          >
            <FilterItemContainer>
              <IconCircle primaryColor={primaryColor}>
                <IconContainer>
                  <GFImage name={activityIcons.playoffs} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
                </IconContainer>
              </IconCircle>
              <FilterItemText numberOfLines={1}>Playoffs</FilterItemText>
            </FilterItemContainer>
          </SelectableItem>
          <PlayoffsDivider />
        </>
      );

      return [playoffsItem, ...activityItems];
    }

    return activityItems;
  };

  const renderOtherItems = () => {
    return sortedArray(otherActivities, (a, b) => a.name.localeCompare(b.name)).map(filter => {
      const selected = selectedFilter.activities.includes(filter.name);
      const iconName = filter.name.toLowerCase().replace(/ /g, '');
      const activityIcon = activityIcons.hasOwnProperty(iconName)
        ? activityIcons[iconName as keyof typeof activityIcons]
        : activityIcons.unlabeled;
      return (
        <SelectableItem
          key={filter.name}
          value={filter.name}
          isSelected={selected}
          onPress={() => onActivityFilterPress(selected, filter)}
        >
          <FilterItemContainer>
            <IconCircle primaryColor={primaryColor}>
              <IconContainer>
                <GFImage name={activityIcon} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
              </IconContainer>
            </IconCircle>
            <FilterItemText numberOfLines={1}>{filter.name}</FilterItemText>
          </FilterItemContainer>
        </SelectableItem>
      );
    });
  };

  const onLevelFilterPress = (selected: boolean, level: string) => {
    if (selected) {
      setSelectedFilter({
        ...selectedFilter,
        levels: selectedFilter.levels.filter(activity => activity !== level)
      });
    } else {
      setSelectedFilter({ ...selectedFilter, levels: [...selectedFilter.levels, level] });
    }
  };

  const renderLevelItems = () =>
    levels.map(level => {
      const selected = selectedFilter.levels.includes(level);
      const levelIconPath = `level-icons/${level.toLowerCase().replace(' ', '-')}.svg`;
      return (
        <SelectableItem
          key={level}
          value={level}
          isSelected={selected}
          onPress={() => onLevelFilterPress(selected, level)}
        >
          <FilterItemContainer>
            <IconCircle primaryColor={primaryColor}>
              <IconContainer>
                <GFImage name={levelIconPath} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
              </IconContainer>
            </IconCircle>
            <FilterItemText numberOfLines={1}>{level}</FilterItemText>
          </FilterItemContainer>
        </SelectableItem>
      );
    });

  const onGenderFilterPress = (selected: boolean, gender: string) => {
    if (selected) {
      setSelectedFilter({
        ...selectedFilter,
        genders: selectedFilter.genders.filter(activity => activity !== gender)
      });
    } else {
      setSelectedFilter({ ...selectedFilter, genders: [...selectedFilter.genders, gender] });
    }
  };

  const renderGenderItems = () =>
    genders.map(gender => {
      const selected = selectedFilter.genders.includes(gender);
      const genderIconPath = `gender-icons/${gender.toLowerCase()}.png`;
      return (
        <SelectableItem
          key={gender}
          value={gender}
          isSelected={selected}
          onPress={() => onGenderFilterPress(selected, gender)}
        >
          <FilterItemContainer>
            <IconCircle primaryColor={primaryColor}>
              <IconContainer>
                <GFImage name={genderIconPath} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
              </IconContainer>
            </IconCircle>
            <FilterItemText numberOfLines={1}>{gender}</FilterItemText>
          </FilterItemContainer>
        </SelectableItem>
      );
    });

  const onHomeAwayFilterPress = (selected: boolean, location: string) => {
    if (selected) {
      setSelectedFilter({
        ...selectedFilter,
        homeAway: selectedFilter.homeAway.filter(activity => activity !== location)
      });
    } else {
      setSelectedFilter({ ...selectedFilter, homeAway: [...selectedFilter.homeAway, location] });
    }
  };

  const renderHomeAwayItems = () =>
    homeAwayFilters.map(location => {
      const selected = selectedFilter.homeAway.includes(location);
      return (
        <SelectableItem
          key={location}
          value={location}
          isSelected={selected}
          onPress={() => onHomeAwayFilterPress(selected, location)}
        >
          <FilterItemContainer>
            <IconCircle primaryColor={primaryColor}>
              <IconContainer>
                <GFImage name={location.toLowerCase() + '.png'} width={12.25} height={10.55} tintColor={'#F6F6FA'} />
              </IconContainer>
            </IconCircle>
            <FilterItemText numberOfLines={1}>{location}</FilterItemText>
          </FilterItemContainer>
        </SelectableItem>
      );
    });

  const playoffCount = selectedFilter.playoffs ? 1 : 0;
  const selectedNormalActivitiesCount =
    selectedFilter.activities.filter(activity => !otherActivitiesNames.includes(activity)).length + playoffCount;
  const selectedOtherCount = selectedFilter.activities.filter(activity =>
    otherActivitiesNames.includes(activity)
  ).length;

  const selectedLevelsCount = appliedFilter.levels.length;
  const selectedGendersCount = appliedFilter.genders.length;
  const selectedHomeAwayCount = appliedFilter.homeAway.length;

  const handleDropdownDismiss = () => {
    if (hasFilterChanged(selectedFilter, appliedFilter)) applyFilter(FILTER_BATCH, true, selectedFilter);
  };

  return shouldDisplayFilters ? (
    <Container isMobile={isMobile} {...testProperties(schoolPage.filterCategory)}>
      {normalActivities.length > 0 && (
        <>
          <DrawerContent
            drawerId={MULTISELECT_BOTTOM_DRAWER + 'activity-filters'}
            content={
              <FilterDrawer
                shouldShowPlayOff={shouldShowPlayOff}
                filters={sortedFilters}
                filterCategory='activities'
                primaryColor={primaryColor}
                selectedFilter={selectedFilter}
                drawerId={MULTISELECT_BOTTOM_DRAWER + 'activity-filters'}
                dialogSubtitle={'Activities'}
                applyFilter={applyFilter}
              />
            }
            drawerSize={calculateDrawerHeight(sortedFilters.length)}
            position={'bottom'}
            dependentData={appliedFilter}
          />
          <Dropdown
            mobileDrawerId={MULTISELECT_BOTTOM_DRAWER + 'activity-filters'}
            renderPlaceholder={isOpen => renderPlaceholder(isOpen, selectedNormalActivitiesCount, 'Activities')}
            filtersSelected={selectedNormalActivitiesCount > 0}
            cancelFunction={() => {
              resetFilters(FILTER_ACTIVITY);
              setSelectedFilter({
                ...selectedFilter,
                activities: filterOutOtherActivities(selectedFilter.activities),
                playoffs: false
              });
            }}
            dismissFunction={handleDropdownDismiss}
          >
            {renderActivityItems()}
          </Dropdown>
        </>
      )}

      {shouldShowLevels && (
        <>
          <DrawerContent
            drawerId={MULTISELECT_BOTTOM_DRAWER + 'level-filters'}
            content={
              <FilterDrawer
                filters={levels}
                filterCategory='levels'
                primaryColor={primaryColor}
                selectedFilter={selectedFilter}
                drawerId={MULTISELECT_BOTTOM_DRAWER + 'level-filters'}
                dialogSubtitle={'Levels'}
                applyFilter={applyFilter}
              />
            }
            drawerSize={calculateDrawerHeight(levels.length)}
            position={'bottom'}
            dependentData={appliedFilter}
          />
          <Dropdown
            mobileDrawerId={MULTISELECT_BOTTOM_DRAWER + 'level-filters'}
            renderPlaceholder={isOpen => renderPlaceholder(isOpen, selectedFilter.levels.length, 'Levels')}
            filtersSelected={selectedLevelsCount > 0}
            cancelFunction={() => {
              resetFilters(FILTER_LEVEL);
              setSelectedFilter({ ...selectedFilter, levels: [] });
            }}
            dismissFunction={handleDropdownDismiss}
          >
            {renderLevelItems()}
          </Dropdown>
        </>
      )}

      {shouldShowGenders && (
        <>
          <DrawerContent
            drawerId={MULTISELECT_BOTTOM_DRAWER + 'gender-filters'}
            content={
              <FilterDrawer
                filters={genders}
                filterCategory='genders'
                primaryColor={primaryColor}
                selectedFilter={selectedFilter}
                drawerId={MULTISELECT_BOTTOM_DRAWER + 'gender-filters'}
                dialogSubtitle={'Genders'}
                applyFilter={applyFilter}
              />
            }
            drawerSize={calculateDrawerHeight(genders.length)}
            position={'bottom'}
            dependentData={appliedFilter}
          />

          <Dropdown
            mobileDrawerId={MULTISELECT_BOTTOM_DRAWER + 'gender-filters'}
            renderPlaceholder={isOpen => renderPlaceholder(isOpen, selectedFilter.genders.length, 'Gender')}
            filtersSelected={selectedGendersCount > 0}
            cancelFunction={() => {
              resetFilters(FILTER_GENDER);
              setSelectedFilter({ ...selectedFilter, genders: [] });
            }}
            dismissFunction={handleDropdownDismiss}
          >
            {renderGenderItems()}
          </Dropdown>
        </>
      )}

      {otherActivities.length > 0 && (
        <>
          <DrawerContent
            drawerId={MULTISELECT_BOTTOM_DRAWER + 'other-filters'}
            content={
              <FilterDrawer
                filters={otherActivities}
                filterCategory='activities'
                primaryColor={primaryColor}
                selectedFilter={selectedFilter}
                drawerId={MULTISELECT_BOTTOM_DRAWER + 'other-filters'}
                dialogSubtitle={'Other'}
                applyFilter={applyFilter}
              />
            }
            drawerSize={calculateDrawerHeight(otherActivities.length)}
            position={'bottom'}
            dependentData={appliedFilter}
          />

          <Dropdown
            mobileDrawerId={MULTISELECT_BOTTOM_DRAWER + 'other-filters'}
            renderPlaceholder={isOpen => renderPlaceholder(isOpen, selectedOtherCount, 'Other')}
            filtersSelected={selectedOtherCount > 0}
            cancelFunction={() => {
              resetFilters(FILTER_OTHER);
              setSelectedFilter({
                ...selectedFilter,
                activities: filterOnlyOtherActivities(selectedFilter.activities)
              });
            }}
            dismissFunction={handleDropdownDismiss}
          >
            {renderOtherItems()}
          </Dropdown>
        </>
      )}

      {hasLocationFilter && (
        <>
          <DrawerContent
            drawerId={MULTISELECT_BOTTOM_DRAWER + 'home-away-filters'}
            content={
              <FilterDrawer
                filters={homeAwayFilters}
                filterCategory='homeAway'
                primaryColor={primaryColor}
                selectedFilter={selectedFilter}
                drawerId={MULTISELECT_BOTTOM_DRAWER + 'home-away-filters'}
                dialogSubtitle={'Home/Away'}
                applyFilter={applyFilter}
              />
            }
            drawerSize={calculateDrawerHeight(otherActivities.length)}
            position={'bottom'}
            dependentData={appliedFilter}
          />
          <Dropdown
            mobileDrawerId={MULTISELECT_BOTTOM_DRAWER + 'home-away-filters'}
            renderPlaceholder={isOpen => renderPlaceholder(isOpen, selectedHomeAwayCount, 'Home/Away')}
            filtersSelected={selectedHomeAwayCount > 0}
            cancelFunction={() => {
              resetFilters(FILTER_HOMEAWAY);
              setSelectedFilter({ ...selectedFilter, homeAway: [] });
            }}
            dismissFunction={handleDropdownDismiss}
          >
            {renderHomeAwayItems()}
          </Dropdown>
        </>
      )}
    </Container>
  ) : (
    <EmptyTag />
  );
};
