import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { ToggleFavorite } from '@gf/cross-platform-lib/modules/school/components/ToggleFavorite/ToggleFavorite';
import { styles } from './SchoolHeaderLegacy.styles';
import { GFImage, StyledText } from '@gf/cross-platform-lib/components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { invertColor, schoolPage, testProperties } from '@gf/cross-platform-lib/utils';
import { Text, TouchableOpacity, View } from 'react-native';
import { ArrowRight } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { SCHOOL_DISTRICT_LIST, useDrawerContext } from '@gf/cross-platform-lib/providers';
import { TeamSitePromotion } from '@gf/cross-platform-lib/components/TeamSitePromotion/TeamSitePromotion';
import { useLaunchDarklyContext, ITeamSitePromotion } from '@gf/cross-platform-lib/providers/LaunchDarkly';

const Wrapper = styled(View)`
  ${styles.wrapper}
`;

const SchoolProfile = styled(View)`
  ${styles.schoolProfile}
`;

const SchoolDetailName = styled(Text)`
  ${styles.schoolDetailName}
`;

const ContentWrapper = styled(View)`
  ${styles.contentWrapper}
`;

const ActionButtonsWrapper = styled(View)`
  ${styles.actionButtonsWrapper}
`;

const Logo = styled(View)`
  ${styles.logo}
`;

const SchoolDetailWrapper = styled(View)`
  ${styles.schoolDetailWrapper}
`;

const SchoolDetailNameWrapper = styled.View`
  ${styles.schoolDetailNameWrapper}
`;

const ViewAllSchoolsButton = styled(TouchableOpacity)<{ color: string }>`
  ${styles.viewAllSchoolsButton}
`;

const SitePromoContainer = styled.View`
  ${styles.sitePromoContainer}
`;

export interface SchoolHeaderProps {
  primaryColor?: string;
  schoolName: string;
  logo?: string;
  isFavorite: boolean;
  toggleFavorite: () => void;
  customPageName?: string;
  showDistrictButton: boolean;
  schoolId: string;
}

export const SchoolHeaderLegacy = ({
  primaryColor = '#000000',
  schoolName = '',
  logo,
  isFavorite,
  toggleFavorite,
  customPageName,
  showDistrictButton,
  schoolId
}: SchoolHeaderProps) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const schoolIdsToSiteUrls = launchDarklyContext.features['teams-sites-promotion'].variation as {
    [key: string]: ITeamSitePromotion;
  };
  const schoolSite = schoolIdsToSiteUrls[schoolId];
  const { isMobile } = useMediaQuery();
  const schoolLogo = logo || 'gofan-icon.png';
  let title = customPageName || schoolName;
  const { openDrawer } = useDrawerContext();
  const invertedColor = invertColor(primaryColor, true);

  return (
    <Wrapper {...testProperties(schoolPage.schoolInfoSection)} backgroundColor={primaryColor} isMobile={isMobile}>
      <ContentWrapper isMobile={isMobile}>
        <SchoolProfile showDistrictButton={showDistrictButton && !isMobile}>
          <SchoolDetailWrapper>
            <Logo isMobile={isMobile}>
              <GFImage src={schoolLogo} width={[72, 120]} height={[72, 120]} />
            </Logo>
            <SchoolDetailNameWrapper>
              <SchoolDetailName
                id='lblSchoolName'
                textColor={invertedColor}
                isMobile={isMobile}
                numberOfLines={2}
                {...testProperties(schoolPage.schoolName)}
              >
                {title}
              </SchoolDetailName>
            </SchoolDetailNameWrapper>
            {schoolSite !== undefined && !isMobile && (
              <SitePromoContainer isMobile={isMobile}>
                <TeamSitePromotion
                  hexColor={invertedColor}
                  displayName={schoolSite.displayName}
                  src={schoolSite.src}
                  enableBoldText={true}
                  schoolId={schoolId}
                  componentLocation='event list'
                />
              </SitePromoContainer>
            )}
          </SchoolDetailWrapper>
          <ActionButtonsWrapper>
            <ToggleFavorite isFavorite={isFavorite} toggleFavorite={toggleFavorite} />
            {showDistrictButton && !isMobile && (
              <ViewAllSchoolsButton onPress={() => openDrawer(SCHOOL_DISTRICT_LIST)} color={invertedColor}>
                <StyledText color={invertedColor}>View all schools</StyledText>
                <ArrowRight fill={invertedColor} height={16} width={16} />
              </ViewAllSchoolsButton>
            )}
          </ActionButtonsWrapper>
        </SchoolProfile>
        {schoolSite !== undefined && isMobile && (
          <SitePromoContainer isMobile={isMobile}>
            <TeamSitePromotion
              hexColor={invertedColor}
              displayName={schoolSite.displayName}
              src={schoolSite.src}
              enableBoldText={true}
              schoolId={schoolId}
              componentLocation='event list'
            />
          </SitePromoContainer>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
