import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventFooter.styles';
import { EventFooterItem } from '../EventFooterItem';
import { EventInvolvedSchools } from '../EventInvolvedSchools';
import { School } from '@gf/cross-platform-lib/interfaces';
import {
  DATE_WITH_TIME_ZONE_FORMAT,
  eventCardComponent,
  getUnixTime,
  testProperties
} from '@gf/cross-platform-lib/utils';
import { View } from 'react-native';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const TagWrapper = styled(View)`
  ${styles.tagWrapper};
`;

export interface EventFooterProps {
  isAllDayEvent: boolean;
  startDateTime: string;
  endDateTime: string;
  isPlayoff: boolean;
  isFeatured: boolean;
  venueTitle: string;
  isMobile: boolean;
  involvedSchools: School[];
  isShowInvolvedSchools: boolean;
  homeSchoolId: string;
  isSameDay: boolean;
  timeZone: string;
  theme?: string;
}

export function EventFooter({
  isAllDayEvent,
  startDateTime,
  isPlayoff,
  isFeatured,
  venueTitle,
  isMobile,
  involvedSchools,
  isShowInvolvedSchools,
  homeSchoolId,
  isSameDay,
  timeZone,
  theme
}: EventFooterProps) {
  const time = getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT).format('h:mm A');

  return (
    <Wrapper {...testProperties(eventCardComponent.secondarySection)} isMobile={isMobile}>
      <TagWrapper isShowInvolvedSchools={isShowInvolvedSchools} isMobile={isMobile}>
        <EventFooterItem iconName={isMobile ? 'locationFilled' : 'location'} title={venueTitle} />
        {!isAllDayEvent && !isSameDay && <EventFooterItem iconName='time' title={`Starts at ${time}`} />}
        {isPlayoff && <EventFooterItem iconName='playoffIcon' title='Playoff' />}
        {isFeatured && <EventFooterItem iconName='star' title='Featured' />}
        {theme && <EventFooterItem iconName='themeIcon' title={theme} />}
      </TagWrapper>
      {isShowInvolvedSchools && involvedSchools.length > 0 && (
        <EventInvolvedSchools involvedSchools={involvedSchools} isMobile={isMobile} homeSchoolId={homeSchoolId} />
      )}
    </Wrapper>
  );
}
