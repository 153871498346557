import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './Featured.styles';
import { View } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';

const IconContainer = styled(View)`
  ${styles.iconContainer}
`;

export interface FeaturedProps {
  primaryColor?: string;
  textColor: string;
  isMobile: boolean;
}

export const Featured = ({ primaryColor, textColor, isMobile }: FeaturedProps) => (
  <IconContainer backgroundColor={primaryColor || '#000000'} isMobile={isMobile}>
    <Carbonicons color={textColor} name='starFilled' size={isMobile ? 12 : 16} />
  </IconContainer>
);
