import * as React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventListSection.styles';
import {
  isEventActivityFundraiser,
  isFeaturedEvent,
  schoolPage,
  SelectedFilter,
  testProperties
} from '@gf/cross-platform-lib/utils';

import { EventItem } from '../EventItem';
import { NoEventFilter } from '../NoEventFilter';
import { EventSeason, School, IEventSponsorship, EventSeasonCombined } from '@gf/cross-platform-lib/interfaces';
import { SeasonItem } from '../SeasonItem';
import { FundraiserItem } from '../FundraiserItem';
import { Text, TouchableOpacity, View } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import { AdBanner } from '@gf/cross-platform-lib/components';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';

const EventListSectionWrapper = styled(View)`
  ${styles.eventListSectionWrapper}
`;

const EventListBar = styled(View)<{ isMobile: boolean }>`
  ${styles.eventListBar};
`;

const ListBarTitle = styled(TouchableOpacity)<{ isMobile: boolean }>`
  ${styles.listBarTitle};
`;

const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;
const SectionText = styled(Text)`
  ${styles.sectionText};
`;

const EventListWrapper = styled(View)`
  ${styles.eventListWrapper};
`;

const RefreshButton = styled(View)`
  transform: rotate(-45deg);
  cursor: pointer;
`;

const AdBannerWrapper = styled(View)`
  ${styles.adBannerWrapper};
`;

interface EventListSectionProps {
  title: string;
  events: EventSeason[];
  schoolDetail: School;
  schoolBorderColor?: string;
  textId?: string;
  applyFilter: (type: string, selected: boolean, params: any) => void;
  handleResetFilters: () => void;
  refreshEvents: () => void;
  isMobile: boolean;
  isApplyFilter: boolean;
  count: number;
  testId?: string;
  sponsor?: IEventSponsorship;
  todayEventsCount: number;
  upcomingEventsCount: number;
  appliedFilter: SelectedFilter;
}

export const EventListSection = ({
  title,
  events = [],
  schoolDetail,
  schoolBorderColor,
  textId,
  isMobile,
  applyFilter,
  handleResetFilters = () => null,
  refreshEvents,
  count,
  isApplyFilter,
  testId = '',
  sponsor,
  todayEventsCount,
  upcomingEventsCount,
  appliedFilter
}: EventListSectionProps) => {
  const hiddenEventsRef = React.useRef(new Set<EventSeasonCombined>());
  const [isShowingLess, setIsShowingLess] = React.useState(false);
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: adsEnabled = false } = launchDarklyContext?.features['ads-enabled'] || {};

  const renderedEventList = React.useMemo(() => {
    return events.map((item, index) => {
      const isFundraiser: boolean = isEventActivityFundraiser(item);

      const showAd =
        adsEnabled &&
        index === 0 &&
        (todayEventsCount > 0 && upcomingEventsCount > 0 ? textId === 'lblTodaysEvents' : true);

      if (isFundraiser) {
        return (
          <View key={item.id}>
            <FundraiserItem
              isMobile={isMobile}
              fundraiserItem={item}
              schoolId={schoolDetail?.huddleId || ''}
              industryCode={schoolDetail?.industryCode || ''}
              isFeatured={isFeaturedEvent(item.schoolsFeaturedOn || [], schoolDetail?.huddleId || '')}
              primaryColor={schoolBorderColor}
              eventCount={item.eventIds?.length}
              donationGoal={Number(item.goal)}
              barColor={schoolDetail.primaryColor}
              sponsor={sponsor}
            />
            {showAd && (
              <AdBannerWrapper isMobile={isMobile}>
                <AdBanner
                  sizes={isMobile ? [320, 50] : [728, 90]}
                  unitId={'/6355419/Travel/Europe/France/Paris'}
                  slotId={`test`}
                />
              </AdBannerWrapper>
            )}
          </View>
        );
      } else if (item.isSeason && appliedFilter.homeAway.sort((a, b) => b.localeCompare(a))[0] !== 'Away') {
        return (
          <View key={item.id}>
            <SeasonItem
              isMobile={isMobile}
              key={item.id}
              seasonItem={item}
              schoolId={schoolDetail?.huddleId || ''}
              primaryColor={schoolBorderColor}
              eventCount={item.eventIds?.length}
              sponsor={sponsor}
            />
            {showAd && (
              <AdBannerWrapper isMobile={isMobile}>
                <AdBanner
                  sizes={isMobile ? [320, 50] : [728, 90]}
                  unitId={'/6355419/Travel/Europe/France/Paris'}
                  slotId={`test`}
                />
              </AdBannerWrapper>
            )}
          </View>
        );
      } else if (!item.isSeason) {
        return (
          <View key={item.id}>
            <EventItem
              isMobile={isMobile}
              key={`event-${index}`}
              eventItem={item}
              isAway={
                item.schoolHuddleId !== schoolDetail?.huddleId && item.financialSchoolID !== schoolDetail.huddleId
              }
              schoolId={schoolDetail?.huddleId || ''}
              schoolType={schoolDetail?.gofanSchoolType || ''}
              isFeatured={isFeaturedEvent(item.schoolsFeaturedOn || [], schoolDetail?.huddleId || '')}
              primaryColor={schoolBorderColor}
              sponsor={sponsor}
            />
            {showAd && (
              <AdBannerWrapper isMobile={isMobile}>
                <AdBanner
                  sizes={isMobile ? [320, 50] : [728, 90]}
                  unitId={'/6355419/Travel/Europe/France/Paris'}
                  slotId={`test`}
                />
              </AdBannerWrapper>
            )}
          </View>
        );
      } else {
        hiddenEventsRef.current.add(item);
        return null;
      }
    });
  }, [events, schoolDetail, isMobile, schoolBorderColor, sponsor]);
  const eventCounts = count - hiddenEventsRef.current.size;

  return (
    <EventListSectionWrapper {...testProperties(testId || '')} isShowingLess={isShowingLess}>
      <EventListBar isMobile={isMobile}>
        <ListBarTitle
          onPress={() => {
            setIsShowingLess(!isShowingLess);
          }}
        >
          <SectionText id={textId}>{`${title} (${eventCounts})`}</SectionText>
          <IconWrapper>
            <Carbonicons color='#282828' name={!isShowingLess ? 'chevron-up' : 'chevron-down'} size={20} />
          </IconWrapper>
        </ListBarTitle>

        <TouchableOpacity onPress={refreshEvents} {...testProperties(schoolPage.reloadEvent)}>
          <RefreshButton>
            <Carbonicons name='refresh' size={isMobile ? 16 : 20} />
          </RefreshButton>
        </TouchableOpacity>
      </EventListBar>
      {eventCounts > 0 ? (
        <EventListWrapper id={`lstEventBox-${textId}`} isShowingLess={isShowingLess}>
          {renderedEventList}
        </EventListWrapper>
      ) : isApplyFilter ? (
        <NoEventFilter
          key='lblNoEvent'
          isMobile={isMobile}
          handleResetFilters={handleResetFilters}
          applyFilter={applyFilter}
        />
      ) : null}
    </EventListSectionWrapper>
  );
};
